// Styles for Forms
// --------------------------------------------------

#node-edit .form-actions input {
  background-color: white;
  color: #333;
  border-color: #C1C1C1;
}

form .form-actions {
  background-color: transparent;
}
